import React from 'react'

const Modal = ({ setOpenModal }) => {
  return (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-70"
          onClick={() => setOpenModal(false)}
        >
        </div>
        <div className="flex items-center min-h-screen px-4 py-10">
          <div className="relative w-full max-w-lg px-4 py-6 mx-auto bg-white rounded-md shadow-lg">
            <div className="mt-3">
              <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-emerald-100 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60"      viewBox="0 0 30 30" className="w-6 h-6 text-emerald-600" fill="currentColor"
                >
                  <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
                </svg>
              </div>
              <div className="mt-2 text-center sm:px-4 sm:text-left w-full">
                <p className="mt-2 text-large leading-relaxed text-gray-500 ">
                  Thank you for submitting your estimate request to Pool Tile Savers! The information you have provided will enable us to provide an accurate assessment of your need. An estimate will be prepared and sent our withing 24 business hours.
                </p>
                <p className='font-semibold mt-3'>Next steps!</p>
                <ol type='1' className='list-decimal ml-6'>
                  <li>Your estimate will be emailed and texted to the contact data you provided.</li>
                  <li>Simply click the “Approve and Pay Deposit button”. The deposit of $25 to reserve your serviced date will reduce your estimate total.</li>
                  <li>A service date and time will be emailed and texted to you with the contact data you provided.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
