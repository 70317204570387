import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <BrowserRouter>
    <App />
    <ToastContainer 
      autoClose={5000}
      pauseOnFocusLoss={false}
      limit={3}
      transition={Slide}
      pauseOnHover={true}
      newestOnTop={true}
      position={'top-right'}
    />
  </BrowserRouter>
);
