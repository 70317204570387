import React, { useState, useCallback, useLayoutEffect } from 'react'
import { db } from '../firebase.js'
import { doc, getDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import BookingService from './BookingService.js'
import HandymanForm from './HandymanForm.js'
import Spinner from '../components/Spinner.js'
import { toast } from 'react-toastify'
import PoolTileSaverForm from './PoolTileSaverForm.js'
import PureWaterNorthWestForm from './PureWaterNorthWestForm.js'
import Zarazau from './Zarazau.jsx'
import Footer from '../components/Footer/index.jsx'

const Index = () => {

  const { client_id } = useParams()

  const [loading, setLoading] = useState(false)
  const [isClientId, setIsClientId] = useState(false)
  const [profile, setProfile] = useState(null)

  const getClientId = useCallback(async () => {

    try {
      setLoading(true);
      const docRef = doc(db, 'client_info', client_id)
      const docSnap = await getDoc(docRef)

      if(docSnap.exists()){
        setIsClientId(true)
        setProfile(docSnap.data())
      } else {
        setIsClientId(false)
      }
    } catch (err) {
      console.error(err)
      toast.error(err.message)
    } finally {
      setLoading(false)
    }

  }, [client_id])

  useLayoutEffect(() => {
    getClientId()

  }, [getClientId])

  if (loading) {
    return (
      <div className='min-h-screen flex justify-center items-center'>
        <Spinner width='w-16' height='h-16' borderColor='border-blue-600' />
      </div>
    )
  }

  if (!isClientId) {
    return (
      <div className='min-h-screen flex justify-center items-center bg-[#1A2238]'>
        <h1 className='text-4xl text-white font-extrabold'>404 Not Found</h1>
      </div>
    )
  }

  const CurrentForm = () => {
    let form
    switch (client_id) {
      case 'handymanonpurpose':
        form = <HandymanForm profile={profile} />
        break
      case 'pooltilesavers':
        form = <PoolTileSaverForm profile={profile} />
        break
      case 'purewaternorthwest':
        form = <PureWaterNorthWestForm profile={profile} />
        break
      case 'zarazuapainting':
      case 'armoredpainting':
        form = <Zarazau profile={profile} />
        break
      default:
        form = <BookingService profile={profile} />
        break
    }

    return form
  }

  return (
    <>
      <CurrentForm />
      <Footer clientId={client_id} />
    </>
  )
}

export default Index