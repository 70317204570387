import React from "react";
import CameraIcon from "../../assets/images/camera.png";

const ImageUploader = ({ images, removeImage, onChange }) => {
	return (
		<div className="bg-gray-100 w-full rounded-md pb-6 pt-3">
			{
				<div className="flex justify-center flex-col items-center">
					<p className="text-center font-semibold">Please provide a photo of your project.</p>
					<p className="text-center font-semibold">
						These will give us a better understanding of your project. We can then make
						recommendations to you and provide a quote.
					</p>
					<p className="mb-2 font-semibold">Click on icon to select images</p>
					<label htmlFor="filesUploader" className="cursor-pointer">
						<img src={CameraIcon} alt="Camera icon" width="100" height="100" />
					</label>
					<input
						type="file"
						id="filesUploader"
						onChange={onChange}
						multiple
						hidden={true}
						accept="image/*, video/*"
					/>
					{Array.isArray(images) && images.length > 0 && (
						<ul className="text-black">
							{images.map((image, i) => {
								return (
									<li key={image.name}>
										{image.name}
										<span className="cursor-pointer" onClick={() => removeImage(image.name)}>
											&#10006;
										</span>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			}
		</div>
	);
};

export default ImageUploader;
