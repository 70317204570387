// import * as yup from "yup";

// export const validateForm = yup.object({
// 	firstName: yup.string().required("Firstname is required").validate('yes'),
// 	lastName: yup.string().required("Lastname is required"),
// 	email: yup.string().email().required('Email is required'),
// 	phone: yup.string().required("Phone is required"),
// 	address: yup.string().required("Address is required"),
// 	city: yup.string().required("City is required"),
// 	state: yup.string().required("State is required"),
// 	zip: yup.number().min(5).required("Address is required"),
// 	description: yup.string().required("description is required"),
// 	aboutUs: yup.string().required("About us is required"),
// });

export const validateForm = (form) => {
	if (
		!form.firstName.trim() ||
		!form.lastName.trim() ||
		!form.email.trim() ||
		!form.phone.trim() ||
		!form.address.trim() ||
		!form.city.trim() ||
		!form.state.trim() ||
		!form.zip.trim() ||
		!form.description.trim() ||
		!form.aboutUs.trim() ||
		Object.values(form.service).every((ele) => ele === false)
	) {
		return false;
	} else {
		return true;
	}
};
