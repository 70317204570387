import React, { memo } from 'react'

const GoogleChatWigdet = ({ client_id }) => {
  return (
    <div className='relative my-5 xl:my-0 pr-5 xl:pr-0 flex justify-end xl:block cursor-pointer'>
      <button
        id='chat-widget'
        data-bm-widget-layout="button"
        data-bm-widget-agent-id={process.env.REACT_APP_DATA_BM_WIDGET_AGENT_ID} 
      >
        <span className="bm-widget-button-text">Chat with {client_id}</span>
        <p className='text-sm'><i>Powered by SnapPicFix</i></p>
      </button>
    </div>
  )
}

export default memo(GoogleChatWigdet)
