import { ESTIMATED_FIELDS } from "./consts"

const validateForm = (form, client_id) => {

  if (ESTIMATED_FIELDS.includes(client_id)) {
    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.address.trim() ||
      !form.city.trim() ||
      !form.state.trim() ||
      !form.zip.trim() ||
      !form.description.trim() ||
      !form.sq_feet.trim() ||
      !form.special_attention.trim()
    ) {
      return true
    } else {
      return false
    }
  } else if (client_id === 'blackmanpi') {
    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.description.trim()
    ) {
      return true
    } else {
      return false
    }
  } else if (client_id === 'purewaternorthwest') {
    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.address.trim() ||
      !form.city.trim() ||
      !form.state.trim() ||
      !form.zip.trim() ||
      !form.description.trim() ||
      !form.customerType ||
      !form.homeOwner ||
      !form.numOfPeople ||
      !form.numOfBathroom ||
      !form.wellType
    ) {
      return true
    } else {
      return false
    }
  } else if (client_id === 'rpi_google') {
    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.zip.trim()
    ) {
      return true
    } else {
      return false
    }
  } else if (client_id === 'westover') {
    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.address.trim() ||
      !form.city.trim() ||
      !form.state.trim() ||
      !form.zip.trim() ||
      !form.description.trim() ||
      !form.firstWorkDay
    ) {
      return true
    } else {
      return false
    } 
  } else {
    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.address.trim() ||
      !form.city.trim() ||
      !form.state.trim() ||
      !form.zip.trim() ||
      !form.description.trim()
    ) {
      return true
    } else {
      return false
    }
  }

}

export { 
  validateForm
}
