import { getDownloadURL, uploadBytes, ref } from 'firebase/storage'
import { storage } from '../firebase.js'
import { formatDate } from './helpers.js'

/** 
 * @desc Uploading images and getting imagess URL from storage for booking service page
**/

export const uploadFiles = async (files, client_id) => {

  const fileUrls = []
  
  if (files.length > 0) {
    await Promise.all(files.map(async(file) => {
      let fileName = `booking_service_images/${client_id}/${formatDate()}_${file.name}`
      const fileRef = ref(storage, fileName)
      await uploadBytes(fileRef, file)
      const url = await getDownloadURL(fileRef)
      fileUrls.push(url)
    }))
  } else {
    return null
  }

  return fileUrls
}
