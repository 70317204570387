import moment from 'moment'
import { API_URL } from './Urls'

export const formatDate = () => {
  return moment(new Date()).format('DD-MM-YYYY h:mm')
}

export const isIframe = () => {
  return window !== window.parent ? true : false
}

export const redirectUser = (clientId) => {
  switch (clientId) {
    case 'rpi':
      window.open(API_URL.RPI, '_blank')
      break
    case 'homeandgroundmn':
      window.open(API_URL.HOME_AND_GROUND_MN, '_blank')
      break
      case 'fci':
      window.open(API_URL.FCI_REDIRECT_URL, '_blank')
      break
      case 'mrsks':
      window.open(API_URL.MRSKS_REDIRECT_URL, '_blank')
      break
    default:
      break
  }
}
