export const API_URL = {
  RPI: 'https://raleighpaintandimprovement.com/estimate-request-thank-you-page/',
  HOME_AND_GROUND_MN: 'https://calendly.com/homeandgroundsmn',
  SNAPPICFIX: 'https://www.snappicfix.com/',
  FORM_BOOKING: process.env.REACT_APP_FORM_BOOKING,
  FCI_REDIRECT_URL : 'https://raleigh.floorcoveringsinternational.com/schedule-online/',
  MRSKS_REDIRECT_URL : 'https://book.housecallpro.com/book/Mrs-Ks-Construction-LLC/68d34803a9ee41c98f909973ef56d044?attribution=ScheduleNowhttps://raleigh.floorcoveringsinternational.com/schedule-online/',
  NOTES_URL : 'https://booking-srv-ctd-test.azurewebsites.net/addNote'

}
