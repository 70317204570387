import moment from 'moment'
import axios from "axios";
import { API_URL } from "./Urls"

function convertPhoneNumber(phoneNumber) {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Add the country code and return the converted number
    return digitsOnly.slice(-10);;
}

export const addNotes = async (form_data, images, client_id) => {



  try {
      const firstName = form_data.firstName?.trim();
      const lastName = form_data.lastName?.trim();
      const customerName = `${firstName} ${lastName}`;

      const currentdate = Date.now();
      const newDate = moment(currentdate).format("YYYY-MM-DD hh:mm A");


      let notesMessage = `<${customerName}> submitted a service request `;
      notesMessage += images.length > 0 ? "with images" : "without images";

      const res = await axios.post(API_URL.NOTES_URL, {
          number: convertPhoneNumber(form_data.phone.replace(/\D/g, '')),
          notes: 'spf-form submit : '+ notesMessage,
          datetime: newDate,
          image: images,
          client: client_id,
      });

      // Handle response or further actions here, if needed
  } catch (error) {
      console.error("Error sending the service request:", error);
      // Handle the error or rethrow, as appropriate for your application
  }
}
