import axios from "axios";
import { ESTIMATED_FIELDS } from "./consts";
import { API_URL } from "./Urls"

import { addNotes } from './notes'; // Adjust the path to where your addNotes function is defined

/**
 * @desc Uploading user booking form
**/
export const addBookingSubmission = async (form_data, images, profile) => {

  const bookingSubmissionData = {
    client_id: profile.clientName,
    first_name: form_data.firstName?.trim(),
    last_name: form_data.lastName?.trim(),
    email: form_data.email?.toLowerCase()?.trim(),
    phone: form_data.phone.replace(/\D/g, ''),
    address: form_data.address?.trim(),
    state: form_data.state?.trim(),
    city: form_data.city?.trim(),
    zip: form_data.zip,
    imgs: images,
    cust_notes: form_data.description?.trim(),
    type: 'Form',
  }
  // Call the addNotes function
  try {
    await addNotes(form_data, images, profile.clientName);
    // Further actions after adding notes
  } catch (error) {
    console.error("Error in handleBooking:", error);
  }


  if (profile.clientName === 'handymanonpurpose') {
    bookingSubmissionData.attribution = form_data.attribution?.trim();
    bookingSubmissionData.completion_date = form_data.completion_date?.trim();
  }

  if (ESTIMATED_FIELDS.includes(profile.clientName)) {
    bookingSubmissionData['sq_feet'] = form_data.sq_feet?.trim();
    bookingSubmissionData['special_attention'] = form_data.special_attention?.trim();
  }

  if (profile.clientName === 'pooltilesavers') {
    bookingSubmissionData['company_name'] = form_data.companyName?.trim()
    bookingSubmissionData['property_type'] = form_data.propertyType?.trim()
  }

  if (profile.clientName === 'purewaternorthwest') {
    bookingSubmissionData['customer_type'] = form_data.customerType
    bookingSubmissionData['home_owner'] = form_data.homeOwner
    bookingSubmissionData['num_of_people'] = form_data.numOfPeople
    bookingSubmissionData['num_of_bathroom'] = form_data.numOfBathroom
    bookingSubmissionData['well_type'] = form_data.wellType
  }

  if (profile.clientName === 'blackmanpi') {
    bookingSubmissionData['optSms'] = form_data.optSms
    delete bookingSubmissionData.address
    delete bookingSubmissionData.state
    delete bookingSubmissionData.city
    delete bookingSubmissionData.zip
    delete bookingSubmissionData.imgs
  }

  if (profile.clientName === 'westover') {
    bookingSubmissionData['aboutUs'] = form_data.aboutUs
    bookingSubmissionData['firstWorkDay'] = form_data.firstWorkDay
    bookingSubmissionData['secondWorkDay'] = form_data.secondWorkDay
    bookingSubmissionData['arrivalTime'] = form_data.arrivalTime
  }

  if (profile.clientName === 'zarazuapainting' || profile.clientName === 'armoredpainting') {
    bookingSubmissionData.aboutUs = form_data.aboutUs?.trim();
    bookingSubmissionData.service = form_data.service;
  }

  if (profile.clientName === 'aflac') {
    bookingSubmissionData.employer = form_data.employer?.trim();
  }

  return await axios.post(API_URL.FORM_BOOKING, bookingSubmissionData)
}
