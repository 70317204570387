import { Route, Routes } from "react-router-dom";
import PAGE_404 from "./pages/404";
import Index from "./pages/Index";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/:client_id" element={<Index />} />
        <Route path="*" element={<PAGE_404 />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
