export const ESTIMATED_FIELDS = ['tildustdouspart', 'ecomaids']

export const TYPE_OF_CUSTOMER = [
  {
    value: 'residential',
    name: 'Residential'
  },
  {
    value: 'commercial',
    name: 'Commercial'
  }
]

export const HOME_OWNER = [
  {
    value: 'yes',
    name: 'Yes'
  },
  {
    value: 'no',
    name: 'No'
  },
  {
    value: 'not applicable',
    name: 'Not applicable'
  }
]

export const WELL_TYPE = [
  {
    value: 'private',
    name: 'Private'
  },
  {
    value: 'community',
    name: 'Community'
  },
  {
    value: 'municipal',
    name: 'Municipal'
  },
  {
    value: 'not applicable',
    name: 'Not applicable'
  }
]

export const DESCRIPTION_TEXT = {
  lavenderbenefits: 'Tell me how we can help',
  blackmanpi: 'Tell us a little about what services you are needing',
  brownsmoving: 'Please tell us about your moving and labor project',
  aflac: 'What do you need help with?'
}

export const ZIP_CODE_TYPE = {
  prairieasbestos: 'text',
  guttervacni: 'text'
}

export const ZIP_CODE_LENGTH = {
  prairieasbestos: '7',
  guttervacni: '8'
}

export const HIDE_IMAGE_WIDGET = {
	lavenderbenefits: true,
	zarazuapainting: true,
	armoredpainting: true,
	aflac: true,
  blackmanpi: true
};

export const ZARAZAU_ABDOUT_US = [
	{
		name: "Google",
		value: "Google",
	},
	{
		name: "Friend/Family/Previous Customer",
		value: "Friend/Family/Previous Customer",
	},
	{
		name: "Yard Sign/Company Vehicle",
		value: "Yard Sign/Company Vehicle",
	},
	{
		name: "Social Media",
		value: "Social Media",
	},
	{
		name: "Others",
		value: "Others",
	},
];

export const ZARAZAU_SERVICES = {
	exteriorPainting: "Exterior Painting",
	interiorPainting: "Interior Painting",
	cabinetPainitng: "Cabinet Painting",
	carpentry: "Carpentry",
	drywall: "Drywall",
	gutter: "Gutter Installation",
	roof: "Roof Installation",
	floor: "Floor Installation",
};

export const Z_M_FOOTER = [
  "ansapainting",
  "alvarezpremierpainting",
  "armoredpainting",
  "handlpaintingandservices",
  "jaygopainting",
  "zarazuapainting",
];