import React from 'react'

const FileInput = ({ onChange, label, multiple = false, accept, required = false }) => {

  return (
    <div className='mb-4'>
      <label htmlFor='formFile' className='mb-2 block font-semibold text-[#202059]'>
        {label} { required && <sup className='text-base ml-1'>*</sup> }
      </label>
      <input
        className='file:mr-4 file:py-2 file:px-4 file:border-0 file:text-base file:font-semibold
        file:bg-[#0389D6] file:text-white hover:file:bg-[#bbe6f1]'
        id='formFile'
        type='file'
        onChange={onChange}
        multiple={multiple}
        accept={accept}
      />
    </div>
  )
}

export default FileInput
