import React, { memo } from 'react'
import Spinner from '../Spinner'

const SubmitButton = ({ loading, clientId = null, disabled = false }) => {
  return (
    <div className='flex justify-center my-10'>
      <button disabled={disabled} type='submit' className={'w-36 h-10 text-lg text-white rounded font-semibold '
      + (clientId === 'pooltilesavers' ? 'bg-[#0389D6] hover:bg-[#bbe6f1] hover:text-[#202059]' : 'bg-[#dc3545]') + (disabled ? ' cursor-not-allowed' : ' cursor-pointer')}>
        {loading ? <Spinner width='w-5' height='h-5' borderColor='border-white' /> : 'Submit Form'}
      </button>
    </div>
  )
}

export default memo(SubmitButton)
