import React from "react";
import { Z_M_FOOTER } from "../../utils/consts";

const FooterHref = ({ href, label }) => (
  <a className="cursor-pointer hover:underline" href={href} rel="noopener" target="_parent">
    {label}
  </a>
);

const Footer = ({ clientId }) => {
  const className = "text-[#007bff] ml-2";
  const zmUrl = "https://zmconsultinggroup.com";
  const snapPicFixUrl = "https://www.snappicfix.com";

  return (
    <div className="bg-[#343a40] text-center py-4 italic text-lg">
      <span className=" text-white">Powered by</span>
      <span className={className}>
        {Z_M_FOOTER.includes(clientId) ? (
          <>
            <FooterHref label="ZM Consulting" href={zmUrl} />
            &nbsp;+&nbsp;
            <FooterHref label="SnapPicFix" href={snapPicFixUrl} />
          </>
        ) : (
          <FooterHref label="SnapPicFix" href={snapPicFixUrl} />
        )}
      </span>
    </div>
  );
};

export default Footer;
