import React from "react";

const Label = ({ clientId, text, required }) => {
	const match = clientId === "pooltilesavers";
	return (
		<label
			className={"block w-full mb-2 font-semibold " + (match ? "text-[#202059]" : "text-black")}
		>
			{text} {required && <sup className="text-red-600 text-base ml-1">*</sup>}
		</label>
	);
};

export default Label;
