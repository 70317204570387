import React from 'react'

const PAGE404 = () => {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center bg-[#1A2238]">
      <h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
      <h2 className="text-2xl font-bold text-white tracking-widest mt-5">
        Page Not Found
      </h2>
    </main>
  )
}

export default PAGE404