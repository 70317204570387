import React from "react";
import arrowIcon from "../../assets/svgs/arrowIcon.svg";

const DropDown = ({ value, onClick, data, label, isOpen, toggleDropDown }) => {
	return (
		<div className="mb-4">
			<p className="block font-semibold">
				{label}
				<sup className="text-red-600 text-base ml-1">*</sup>
			</p>
			<div
				onClick={toggleDropDown}
				className={
					"w-full h-10 flex items-center px-4 relative cursor-pointer bg-gray-100 border-transparent mt-1 " +
					(isOpen ? "rounded-t-md" : "rounded-md")
				}
			>
				<div className="flex justify-between items-center w-full">
					<p className="text-base capitalize">{value}</p>
					<img
						src={arrowIcon}
						className={"h-full w-[16px] transform " + (isOpen && "rotate-180")}
						alt="down icon"
					/>
				</div>
				{isOpen && (
					<div className="absolute left-0 bg-white text-center top-10 rounded-b-md w-full z-30 overflow-hidden shadow-md">
						<ul className="list-none">
							{data.map((data) => (
								<li
									onClick={() => onClick(data.value)}
									key={data.value}
									value={data.value}
									className="border-b cursor-pointer text-left px-4 text-gray-800 bg-gray-100 h-10 leading-10 z-40"
								>
									{data.name}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default DropDown;
