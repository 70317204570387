import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase.js";
import { getDoc, doc } from "firebase/firestore";
import { addBookingSubmission } from "../utils/bookingService.js";
import { uploadFiles } from "../utils/FirebaseStorage.js";
import { PtsInput } from "../components/Input.js";
import { initWidget } from "../utils/chatWidget.js";
import GoogleChatWigdet from "../components/GoogleChatWigdet.js";
import { toast } from "react-toastify";
import { ERRORS } from "../constants/Errors";
import TextArea from "../components/TextArea";
import { formatMobileNumber } from "../utils/formatters.js";
import { isIframe } from "../utils/helpers.js";
import SubmitButton from "../components/Button/SubmitButton.js";
import FileInput from "../components/Input/FileInput.js";
import RadioButton from "../components/Input/RadioButton.js";
import Modal from "../components/Modal/Modal.js";
import GroupInput from "../components/Input/GroupInput.js";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const formInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  description: "",
  companyName: "",
  propertyType: "",
};

const PoolTileSaverForm = ({ profile }) => {
  const searchParams = new URLSearchParams(document.location.search);

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [logoUrl, setLogoUrl] = useState();
  const [form, setForm] = useState(formInitialState);
  const [modal, setModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const widget = initWidget();
  const inIframe = isIframe();
  const location = useLocation();
  const navigate = useNavigate();

  const getClientLogo = useCallback(async () => {
    const docRef = doc(db, "logos", profile.clientName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setForm({
        ...form,
        firstName: !searchParams.get("fn") ? "" : searchParams.get("fn"),
        lastName: !searchParams.get("ln") ? "" : searchParams.get("ln"),
        email: !searchParams.get("email") ? "" : searchParams.get("email"),
        phone: !searchParams.get("phone") ? "" : searchParams.get("phone"),
        address: !searchParams.get("address")
          ? ""
          : searchParams.get("address"),
        city: !searchParams.get("city") ? "" : searchParams.get("city"),
        state: !searchParams.get("state") ? "" : searchParams.get("state"),
        zip: !searchParams.get("zip") ? "" : searchParams.get("zip"),
        description: !searchParams.get("desc") ? "" : searchParams.get("desc"),
      });
      setLogoUrl(docSnap.data().logoUrl);
    }
  }, [profile.clientName]);

  useEffect(() => {
    getClientLogo();
  }, [getClientLogo]);

  const inputHandler = (e) => {
    const { value, type, name } = e.target;
    const val = type === "tel" ? value.replace(/\D/g, "") : value;
    setForm({ ...form, [name]: val });
  };

  const phoneNumberHandler = (e) => {
    const number = formatMobileNumber(e.target.value);
    setForm({ ...form, phone: number });
  };

  const fileHandler = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    const formReset = document.getElementById("form");

    if (
      !form.firstName.trim() ||
      !form.lastName.trim() ||
      !form.email.trim() ||
      !form.phone.trim() ||
      !form.address.trim() ||
      !form.city.trim() ||
      !form.state.trim() ||
      !form.zip.trim() ||
      !form.propertyType
    ) {
      toast.error(ERRORS.FILL_ALL_ASTERISK_FIELDS);
    } else if (form.phone.length < 10) {
      toast.error(ERRORS.PHONE_NUM_LENGTH_ERROR);
    } else if (form.zip.trim().length < 5) {
      toast.error(ERRORS.ZIP_CODE_LENGTH_ERROR);
    } else {
      try {
        setLoading(true);
        setDisabledButton(true);
        const filesUrl = await uploadFiles(files, profile.clientName);
        await addBookingSubmission(form, filesUrl, profile);
        resetForm();
        formReset.reset();
        setModal(true);
      } catch (err) {
        catchCallback(err);
      } finally {
        setLoading(false);
        setDisabledButton(false);
      }
    }
  };

  const catchCallback = (err) => {
    toast.error(ERRORS.FORM_FAIL);
    console.error(err);
  };

  const resetForm = () => {
    setFiles([]);
    setForm(formInitialState);
    navigate(location.pathname, { replace: true });
  };

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={profile.clientName} />
        <meta property="og:description" content={profile.clientName} />
        <meta property="og:image" content={logoUrl} />
      </Helmet>
      <div className="pt-20 min-h-screen">
        {modal && <Modal setOpenModal={() => setModal(false)} />}
        <main className="w-full md:w-[510px] px-[5%] md:px-0 lg:w-[800px] mx-auto">
          {!inIframe &&
            (!logoUrl ? (
              <p className="text-base font-semibold text-center">
                Logo not found
              </p>
            ) : (
              <div className="h-[40vh]">
                <img
                  src={logoUrl}
                  alt={`${profile.clientName} logo`}
                  className="w-full h-full object-contain"
                />
              </div>
            ))}

          <p className="text-base lg:text-lg font-medium rounded-md bg-[#0389D6] text-white mt-5 p-5">
            Welcome and Thank You for submitting the basic data below in order
            to obtain your same day quote. The Team at PTS is excited to learn
            the details being inserted and ultimately working with you to bring
            your pool back to life with our safe and proven methods. Please
            complete all of the required fields to gain the quote. At the time
            of your submission, our team will receive the data and communicate
            those details asap. Let's get it clean!
          </p>

          <form id="form" className="mt-10" onSubmit={formSubmitHandler}>
            <GroupInput>
              <PtsInput
                type="text"
                name="firstName"
                value={form.firstName}
                label="First Name"
                onChange={inputHandler}
              />

              <PtsInput
                type="text"
                name="lastName"
                value={form.lastName}
                label="Last Name"
                onChange={inputHandler}
              />
            </GroupInput>

            <GroupInput>
              <PtsInput
                type="email"
                name="email"
                value={form.email}
                label="Email"
                onChange={inputHandler}
              />

              <PtsInput
                type="tel"
                name="phone"
                value={form.phone}
                label="Cell Phone"
                onChange={phoneNumberHandler}
              />
            </GroupInput>

            <PtsInput
              type="text"
              name="address"
              value={form.address}
              label="Street Address"
              onChange={inputHandler}
            />

            <GroupInput>
              <PtsInput
                type="text"
                name="city"
                value={form.city}
                label="City"
                onChange={inputHandler}
              />

              <PtsInput
                type="text"
                name="state"
                value={form.state}
                label="State"
                onChange={inputHandler}
              />

              <PtsInput
                label="Zip"
                type="tel"
                name="zip"
                value={form.zip}
                onChange={inputHandler}
                maxLength="5"
              />
            </GroupInput>

            <PtsInput
              type="text"
              name="companyName"
              value={form.companyName}
              label="Company Name (if applicabale)"
              onChange={inputHandler}
              required={false}
            />

            <div className="flex items-center mb-4">
              <p className="m-0 font-semibold text-[#202059] mr-5">
                Property Type <sup className="text-base ml-1">*</sup>
              </p>
              <RadioButton
                label={"Residential"}
                name={"propertyType"}
                value={"residential"}
                onChange={inputHandler}
                checked={form.propertyType === "residential"}
              />

              <RadioButton
                label={"Commercial"}
                name={"propertyType"}
                value={"commercial"}
                onChange={inputHandler}
                checked={form.propertyType === "commercial"}
              />
            </div>

            <p className="text-base lg:text-lg font-medium rounded-md mb-4 bg-[#0389D6] text-white p-5">
              Photos and a short video are very important for the PTS team to
              fully understand your overall pool setting and needs. Please
              follow the basic photo and video requests below so that we can
              provide you with accurate data.
            </p>

            <FileInput
              label={"Take a wide-angle photo of your entire pool"}
              multiple={false}
              accept={"image/*"}
              onChange={fileHandler}
            />

            <FileInput
              label={
                "Take a wide-angle photo of your entire pool from the opposite side"
              }
              multiple={false}
              accept={"image/*"}
              onChange={fileHandler}
            />

            <FileInput
              label={
                "Take an (a) up-close photo of the area you are most concerned about"
              }
              multiple={false}
              accept={"image/*"}
              onChange={fileHandler}
            />

            <FileInput
              label={
                "Take additional up-close photos of the areas you are most concerned about"
              }
              multiple={true}
              accept={"image/*"}
              onChange={fileHandler}
            />

            <FileInput
              label={
                "Take any additional photos of areas you are concerned about"
              }
              multiple={true}
              accept={"image/*"}
              onChange={fileHandler}
            />

            <FileInput
              label={"Take a short 10-15 second video of your entire pool area"}
              multiple={false}
              accept={"video/*"}
              onChange={fileHandler}
            />

            <TextArea
              label={
                "Comments: (Optional) In your own words, please provide any additional information or request that you have?"
              }
              name="description"
              value={form.description}
              onChange={inputHandler}
              required={false}
              clientId={profile.clientName}
            />

            <SubmitButton
              loading={loading}
              clientId={profile.clientName}
              disabled={disabledButton}
            />
          </form>
        </main>

        {widget && <GoogleChatWigdet client_id={profile.clientName} />}
      </div>
    </>
  );
};

export default PoolTileSaverForm;
