import React from 'react'

const TextArea = (props) => {

  const { name, value, onChange, label, required = true, clientId } = props

  const match = clientId === 'pooltilesavers'
 
  return (
    <div className='mb-4'>
      <label className={'block w-full mb-2 font-semibold ' + (match ? 'text-[#202059]' : 'text-black')}>
        {label} { required && <sup className='text-red-600 text-base ml-1'>*</sup> }
      </label>
      <textarea
        name={name}
        value={value}
        rows={6}
        onChange={onChange}
        className={'resize-none px-4 py-2 mt-1 block w-full focus:bg-white outline-none ' + (match ? 'border border-[#cfd4d8] focus:border-[#0389D6] bg-white' : 'bg-gray-100 border-transparent focus:border-gray-500 border-[1.5px] rounded-md')}>
      </textarea>
    </div>
  )
}

export default TextArea
