import React from 'react'

const RadioButton = ({ label, name, value, onChange, checked }) => {
  return (
    <div className="mr-4 flex items-center ml-5">
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label
        className="mt-px inline-block pl-[0.50rem] font-medium hover:cursor-pointer text-[#595d64]"
        htmlFor={value}
      >
        {label}
      </label>
    </div>
  )
}

export default RadioButton
