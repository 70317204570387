import React from 'react'

const GroupInput = ({ children }) => {
  return (
    <div className='lg:flex lg:justify-between lg:items-center lg:gap-x-6'>
      {children}
    </div>
  )
}

export default GroupInput
