import React, { memo } from 'react'
import { isIframe } from '../../utils/helpers'

const Logo = ({ logoUrl }) => {

  const inIframe = isIframe()

  return (
    <>
      {!inIframe &&
        (!logoUrl ?
          <p className='text-base font-semibold text-center'>Brand image not found</p> :
          <div className='h-[40vh]'>
            <img
              src={logoUrl}
              alt={'Customer logo'}
              className='w-full h-full object-contain'
            />
          </div>
        )
      }
    </>
  )
}

export default memo(Logo)
