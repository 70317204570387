export default function Input (props) {

  const { type, name, value, onChange, label, maxLength, required = true, error = false } = props

  return (
    <div className="mb-4 w-full">
      <label htmlFor={name} className="block font-semibold">{label}
        { required && <sup className='text-red-600 text-base ml-1'>*</sup> }
      </label>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        className="mt-1 h-10 block w-full rounded-md bg-gray-100 border-transparent
        focus:border-gray-500 border-[1.5px] focus:bg-white outline-none px-4"
        {...props}
      />
      {error && error.length > 0 && <small className="text-red-500">{error}</small>}
    </div>
  )
}


export const PtsInput = (props) => {

  const { type, name, value, onChange, label, maxLength, required = true } = props

  return (
    <div className="mb-4 w-full">
      <label htmlFor={name} className="block font-semibold text-[#202059]">{label}
        { required && <sup className='text-base ml-1'>*</sup> }
      </label>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        className="mt-1 h-10 block w-full border border-[#cfd4d8]
        focus:border-[#0389D6] focus:bg-white outline-none px-4"
      />
    </div>
  )
}

export function CheckBox (props) {

  const { checked, name, onChange, label, required = false } = props

  return (
    <>
      <input
        type='checkbox'
        name={name}
        id={name}
        onChange={onChange}
        checked={checked}
      />

      <label htmlFor={name} className="font-semibold ml-2">{label}
        { required && <sup className='text-red-600 text-base ml-1'>*</sup> }
      </label>
    </>
  )
}
